exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-causes-js": () => import("./../../../src/pages/causes.js" /* webpackChunkName: "component---src-pages-causes-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */),
  "component---src-templates-categories-post-index-js": () => import("./../../../src/templates/categories-post/index.js" /* webpackChunkName: "component---src-templates-categories-post-index-js" */),
  "component---src-templates-causes-details-index-js": () => import("./../../../src/templates/causes-details/index.js" /* webpackChunkName: "component---src-templates-causes-details-index-js" */),
  "component---src-templates-event-details-index-js": () => import("./../../../src/templates/event-details/index.js" /* webpackChunkName: "component---src-templates-event-details-index-js" */),
  "component---src-templates-services-details-index-js": () => import("./../../../src/templates/services-details/index.js" /* webpackChunkName: "component---src-templates-services-details-index-js" */),
  "component---src-templates-single-post-index-js": () => import("./../../../src/templates/single-post/index.js" /* webpackChunkName: "component---src-templates-single-post-index-js" */),
  "component---src-templates-tag-post-index-js": () => import("./../../../src/templates/tag-post/index.js" /* webpackChunkName: "component---src-templates-tag-post-index-js" */)
}

